// @ts-nocheck we are going to come back and check each in-use file
/*
Control the visibility of specific UI elements when the app is embedded in an iFrame
 */

(function () {
    if (!window) {
        return;
    }
    const embeddedStyles = document.createElement("style");
    if (window.location !== window.parent.location) {
        // EMBEDDED - Hide any elements specific to the non-embedded app
        embeddedStyles.appendChild(
            document.createTextNode(".hide-if-app-embedded { display: none; }"),
        );

        // EMBEDDED - Hide any elements embedded in the provider tools application
        // Inside an iframe, document.referrer will be set to the same value as the href of the
        // parent window's window.location.
        // When indexOf returns -1, it means the provided string was NOT found as a substring.
        if (document.referrer.indexOf("provider-tools") !== -1) {
            embeddedStyles.appendChild(
                document.createTextNode(".hide-if-embedded-in-provider-tools { display: none; }"),
            );
        }
    } else {
        // NOT EMBEDDED - Hide any elements specific to the embedded app
        embeddedStyles.appendChild(
            document.createTextNode(".hide-if-app-not-embedded { display: none; }"),
        );
    }
    document.head.appendChild(embeddedStyles);
})();

export {};
